import React from 'react'
import styles from '../styles/ZoomSlider.css'

export default function ZoomSlider(props) {
  return (
    <div
      className={styles.zoomSliderContainer}
      style={{ width: props.width || '100%' }}
    >
      <input
        type='range'
        min='1'
        max='100'
        value={props.zoom}
        className={styles.zoomSlider}
        onChange={props.onChangeZoom}
      />
    </div>
  )
}
