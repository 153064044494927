import React from 'react'
import styles from '../styles/SearchBar.css'
import {ReactComponent as Search} from '../images/white/Search.svg'

function SearchBar(props) {
  return (
    <div
      className={
        props.isShowing ? (
          styles.searchBar
        ) : (
          `${styles.hideSearchBar} ${styles.searchBar}`
        )
      }
      style={props.style || {}}
    >
      <form onSubmit={(event) => event.preventDefault()}>
        <label>
          <Search />
          <input
            type='text'
            name='search'
            onChange={props.onChange}
            value={props.searchValue}
          />
        </label>
      </form>
    </div>
  )
}

export default SearchBar
