import React from 'react'
import styles from './styles.module.css'
// import AsideNav from './components/AsideNav' // moved to lcds-v3
import Input from './components/Input'
import Button from './components/Button'
import Header from './components/Header'
import PatientThumb from './components/PatientThumb'
import PatientHeader from './components/PatientHeader'
import AsideDiv from './components/AsideDiv'
import FloatingButton from './components/FloatingButton'
import SearchBar from './components/SearchBar'
import Switch from './components/Switch'
import TestSetThumb from './components/TestSetThumb'
import TestThumb from './components/TestThumb'
import ConfidenceBar from './components/ConfidenceBar'
import Loader from './components/Loader'
import DateInput from './components/DateInput'
import SexInput from './components/SexInput'
import MProc from './components/MProc'
import OrgMemberThumb from './components/OrgMemberThumb'
import PopUpTab from './components/PopUpTab'
import Meter from './components/Meter'
import Notification from './components/Notification'
import XBoxContainer from './components/XBoxContainer'
import Modal from './components/Modal'
import MoleTrackingThumbnail from './components/MoleTrackingThumbnail'
import MoleTrackingThumb from './components/MoleTrackingThumb'
import BirthdayForm from './components/BirthdayForm'
import Timeline from './components/Timeline'
import ZoomSlider from './components/ZoomSlider'

export const ExampleComponent = ({ text }) => {
  return <div className={styles.test}>Example Component!: {text}</div>
}

export {
  Input,
  DateInput,
  Button,
  Header,
  PatientThumb,
  PatientHeader,
  AsideDiv,
  FloatingButton,
  SearchBar,
  Switch,
  TestSetThumb,
  TestThumb,
  ConfidenceBar,
  Loader,
  SexInput,
  MProc,
  OrgMemberThumb,
  PopUpTab,
  Meter,
  Notification,
  XBoxContainer,
  Modal,
  MoleTrackingThumbnail,
  MoleTrackingThumb,
  BirthdayForm,
  Timeline,
  ZoomSlider,
}
